body {
  margin: 0;
  color: black;
  font-size: 18px;
  font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(components/grid/grid.png);
  background-repeat: repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.library {
  width: 97.5%;
  height: 17%;
  padding-left: 2.5%;
  padding-top: 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  top: 0px;
  position: absolute;
  z-index: 10;

  background-color: #1E3C4F;
  border-bottom: 4px solid white;
  transition: top .75s;
  overflow: hidden;
}

.note {
  border-radius: 1.5px;
  margin: 5px;
  background-color: #EEC979;
  background-color: #F0ADA7;
  background-color: #48B0C7;
  width: 100px;
  height: 100px;

  box-shadow: 0 0px 1px 1px black;
  overflow-wrap: break-word;
  position: absolute;

  resize:both;
  overflow:auto;
  z-index: 1;

  transition: transform 0.1s;
}

.note:hover {
  cursor: pointer;
}

.notebase {
  border-radius: 1.5px;
  margin: 5px;
  background-color: red;
  width: 100px;
  height: 100px;
  box-shadow: 0 0px 1px 1px black;
  overflow-wrap: break-word;
}

.notebase:hover {
  cursor: pointer;
}


.note-text {
  margin: 2px;
  margin-left: 5px;
  pointer-events: none;
  z-index: 2;
}

/** Arrow **/
.downArrow {
  /* To center... */
  display: block;
  bottom: 2.5%;
  min-width: 100%;
  position: absolute;
  color: white;
  transform: rotate(180deg);
  transition: .75s;
  animation: bounce 0.9s infinite;
}

.downArrow :hover {
  cursor: pointer;
}

.plus-icon {
  margin: 5px;
  width: 100px;
  height: 100px;
  background-color: none;
  color: white;
  transform: scale(0.5);
  z-index: 1000;
}

.plus-icon:hover {
  cusor: pointer;
}

:link, :visited {
  font-family: inherit;
  text-decoration: none;
  color: white;
}

:root {
  --amplify-container-align: center;
  --amplify-background-color: #f4989D;
  --amplify-primary-color: #16223C;
  --amplify-secondary-color: #16223C;
  --amplify-primary-tint: blue;
  --amplify-primary-shade: red;
}

@keyframes bounce {
  10% { bottom: 4%; }
  50% { bottom: 7%; }
}
